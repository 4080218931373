"use client";

import Image from "next/image";
import { FaMapMarkerAlt, FaEnvelope, FaPhone } from "react-icons/fa";
import Link from "next/link";
import { FaInstagram } from "react-icons/fa";

const Footer = () => {
  return (
    <div className="bg-black text-gray-400 text-center md:text-base">
      {/* Main Section */}
      <section className="py-10 md:py-8 lg:py-8">
        <div className="max-w-screen-xl mx-auto">
          <div className="flex flex-col items-center">
            {/* Logo */}
            <Image
              src="/images/logo.png"
              alt="logo"
              width={200}
              height={111}
              className="mx-auto"
            />
            {/* Content */}
            <div className="mt-6 text-center">
              <h4 className="text-white text-2xl font-bold">Kova Home Stay</h4>
              <div className="mx-auto flex items-center justify-center">
                <a
                  href="https://www.instagram.com/kova_luxury_stay/"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  <FaInstagram size={30} />
                </a>
              </div>

              <div className="mt-4 flex flex-col items-center space-y-4 p-5">
                {/* Address */}
                <div className="flex items-center">
                  <FaMapMarkerAlt className="w-6 h-6 mr-3 self-start  text-yellow-400" />
                  <span>
                    16A, Andheri Industrial Estate, Veera Desai Rd, Andheri
                    West, Mumbai, Maharashtra 400053
                  </span>
                </div>
                {/* Phone */}
                <div className="flex items-center">
                  <FaPhone className="w-5 h-5 mr-3 text-yellow-400" />
                  <span>+91 98098 88830</span>
                </div>
                {/* Email */}
                <div className="flex items-center">
                  <FaEnvelope className="w-5 h-5 mr-3 text-yellow-400" />
                  <span>kvroyalhospitality@gmail.com</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Footer Section */}
      <section className="bg-black py-4 md:py-8 border-t border-gray-700">
        <div className="max-w-screen-xl mx-auto flex-none lg:flex justify-between">
          <p className="text-center mb-0">
            Copyright ©2024. Kova Luxury Stay. All Rights Reserved.
          </p>
          <p className="text-center mb-0">
            <Link className="text-gray-400" href="https://prateeksha.com/">
              Website Design By Prateeksha Web Design
            </Link>
          </p>
        </div>
      </section>
    </div>
  );
};

export default Footer;
