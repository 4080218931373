"use client";

import { useState, useEffect } from "react";
import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon, MenuIcon, XIcon } from "@heroicons/react/outline";
import { FaPhone } from "react-icons/fa";
import Link from "next/link";
import Image from "next/image";

const navigation = [
  { name: "About Us", href: "/about-us" },
  {
    name: "Rental",
    href: null,
    submenu: [
      { name: "All Rental", href: "/rental" },
      {
        name: "Luxury Service Apartments",
        href: "/rental/luxury-service-apartments",
      },
      { name: "Guest Houses", href: "/rental/guest-house" },
      { name: "Luxury Villas", href: "/rental/luxury-villas" },
      { name: "Paying Guest Accommodations", href: "/rental/guest-house" },
    ],
  },
  { name: "Facilities", href: "/facilities" },
  { name: "Contact Us", href: "/contact-us" },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Header = () => {
  const [openSubmenu, setOpenSubmenu] = useState(null);

  const handleSubmenuToggle = (index) => {
    setOpenSubmenu(openSubmenu === index ? null : index);
  };

  const handleClickOutside = (event) => {
    if (!event.target.closest(".submenu")) {
      setOpenSubmenu(null);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleLinkClick = (close) => {
    setOpenSubmenu(null);
    close(); // Close the Disclosure panel
  };

  return (
    <header className="relative z-30">
      {" "}
      {/* Updated z-index */}
      <Disclosure as="nav" className="bg-black py-2">
        {({ open, close }) => (
          <>
            <div className="max-w-screen-xl mx-auto">
              <div className="relative flex items-center justify-between h-24">
                <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                  <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-colorGrey-400 hover:text-white hover:bg-colorGrey-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
                <div className="flex-1 flex items-center justify-center sm:items-center sm:justify-start">
                  <div className="flex-shrink-0">
                    <Link href="/">
                      <Image
                        src="/images/logo.png"
                        alt="logo"
                        width={300}
                        height={92}
                        className="w-20 h-auto lg:w-60 lg:h-auto "
                        unoptimized
                      />
                    </Link>
                  </div>

                  <div className="hidden sm:block sm:ml-6 flex-1 self-center">
                    <div className="flex space-x-10 justify-center">
                      {navigation.map((item, index) =>
                        item.submenu ? (
                          <div key={item.name} className="relative submenu">
                            <button
                              onClick={() => handleSubmenuToggle(index)}
                              className="text-white text-base px-3 py-2 rounded-md font-medium flex items-center uppercase hover:text-yellow-500"
                            >
                              {item.name}
                              <ChevronDownIcon
                                className={`ml-2 h-5 w-5 transition-transform ${
                                  openSubmenu === index
                                    ? "transform rotate-180"
                                    : ""
                                }`}
                              />
                            </button>
                            {openSubmenu === index && (
                              <div className="absolute z-40 bg-white shadow-lg py-4 mt-8 w-80">
                                {" "}
                                {/* Updated z-index */}
                                {item.submenu.map((subitem) => (
                                  <Link
                                    key={subitem.name}
                                    href={subitem.href}
                                    className="block px-6 py-4 text-base text-colorGrey-700 hover:bg-colorGrey-100 uppercase font-medium"
                                    onClick={() => handleLinkClick(close)}
                                  >
                                    {subitem.name}
                                  </Link>
                                ))}
                              </div>
                            )}
                          </div>
                        ) : (
                          <Link
                            key={item.name}
                            href={item.href}
                            className="text-white px-0 py-2 uppercase rounded-md text-base font-medium hover:text-yellow-500"
                            onClick={() => handleLinkClick(close)}
                          >
                            {item.name}
                          </Link>
                        )
                      )}
                    </div>
                  </div>
                  <div className="items-center">
                      <a
                      href="tel:+919809888830"
                    >
                       <div className="flex">
                      <FaPhone className="w-5 h-5 mr-3 text-yellow-400" />
                      <span className="text-white font-normal">+91 98098 88830</span>
                       </div>
                      </a>
                  </div>
                  <div>
                    <Link
                      href="/book-now/"
                      className=" bg-[#fdbb5b] rounded-md text-black font-bold py-3 px-6 lg:px-8 ml-6"
                    >
                      Book Now
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="sm:hidden">
              <div className="px-2 pt-2 pb-3 space-y-1">
                {navigation.map((item, index) =>
                  item.submenu ? (
                    <Disclosure key={item.name} as="div">
                      {({ open: submenuOpen }) => (
                        <>
                          <Disclosure.Button
                            as="button"
                            className="text-white px-3 py-2 rounded-md text-sm font-medium w-full flex items-center justify-between"
                            onClick={() => handleSubmenuToggle(index)}
                          >
                            {item.name}
                            <ChevronDownIcon
                              className={`h-5 w-5 transition-transform ${
                                openSubmenu === index
                                  ? "transform rotate-180"
                                  : ""
                              }`}
                            />
                          </Disclosure.Button>
                          <Disclosure.Panel
                            className={`space-y-1 ${
                              submenuOpen ? "block" : "hidden"
                            }`}
                          >
                            {item.submenu.map((subitem) => (
                              <Link
                                key={subitem.name}
                                href={subitem.href}
                                className="block px-4 py-2 text-sm text-white hover:bg-colorGrey-100"
                                onClick={() => handleLinkClick(close)}
                              >
                                {subitem.name}
                              </Link>
                            ))}
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  ) : (
                    <Link
                      key={item.name}
                      href={item.href}
                      className="block text-white px-3 py-2 rounded-md text-sm font-medium"
                      onClick={() => handleLinkClick(close)}
                    >
                      {item.name}
                    </Link>
                  )
                )}
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </header>
  );
};

export default Header;
